/* eslint react/prop-types: 0 */
import React from "react";

import Layout from "./components/layout";
import SEO from "./components/seo";
import Banner from "./components/banner";
import Welcome from "./components/welcome";
import {
  theme3Vars,
  templateVariableValue,
} from "../../helpers/templateVariables";

import * as theme3Styles from "../theme3/scss/theme3.module.scss";

const Theme3 = ({ result }) => {
  const {
    remarso: {
      domainByURL: {
        website: {
          templateConfiguration,
          template: { templateVariables },
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = result;

  const mainTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-main-title",
    locale,
    defaultLocale
  );

  const section1 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-1-text",
    locale,
    defaultLocale
  );
  const section1Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-1-title",
    locale,
    defaultLocale
  );
  const section1Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme3Vars[1].slug,
    locale,
    defaultLocale
  );

  const section2 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-2-text",
    locale,
    defaultLocale
  );
  const section2Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-2-title",
    locale,
    defaultLocale
  );
  const section2Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme3Vars[2].slug,
    locale,
    defaultLocale
  );

  const section3 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-3-text",
    locale,
    defaultLocale
  );
  const section3Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-3-title",
    locale,
    defaultLocale
  );
  const section3Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme3Vars[3].slug,
    locale,
    defaultLocale
  );

  const metaTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "meta-title-home",
    locale,
    defaultLocale
  );

  return (
    <Layout>
      <SEO metaTitle={metaTitle} />

      <Banner />

      {(() => {
        if (
          mainTitle ||
          section1 ||
          section1Title ||
          section1Logo ||
          section2 ||
          section2Title ||
          section2Logo ||
          section3 ||
          section3Title ||
          section3Logo
        ) {
          return (
            <section
              className={`${theme3Styles.homeContainet} ${theme3Styles.grey} `}
            >
              <div
                className={`${[
                  theme3Styles.wrapper,
                ].join(" ")}`}
              >
                {mainTitle && (
                  <h2 className={`${theme3Styles.secTl} font-black`}>
                    {mainTitle}
                  </h2>
                )}
                <div className={theme3Styles.welRow}>
                  {(section1Title || section1 || section1Logo) && (
                    <div className={theme3Styles.colm}>
                      <Welcome
                        title={section1Title}
                        text={section1}
                        logo={section1Logo}
                      />
                    </div>
                  )}
                  {(section2Title || section2 || section2Logo) && (
                    <div className={theme3Styles.colm}>
                      <Welcome
                        title={section2Title}
                        text={section2}
                        logo={section2Logo}
                      />
                    </div>
                  )}
                  {(section3Title || section3 || section3Logo) && (
                    <div className={theme3Styles.colm}>
                      <Welcome
                        title={section3Title}
                        text={section3}
                        logo={section3Logo}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
          );
        }
      })()}
    </Layout>
  );
};

export default Theme3;
