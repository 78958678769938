import React, { useContext } from "react";
import { navigate } from "gatsby";

import { useInput } from "../../../hooks/input-hook";
import SiteMetaContext from "../../../contexts/SiteMetaContext";
import {
  theme1Vars,
  templateVariableValue,
} from "../../../helpers/templateVariables";
import { translateStatic } from "../../../helpers/translationHelper";
import * as theme1Styles from "../scss/theme1.module.scss";

const Banner = () => {
  // eslint-disable-next-line no-unused-vars
  const { value: keyword, bind: bindKeyword, reset: resetKeyword } = useInput(
    ""
  );

  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          setting: display_settings,
          templateConfiguration,
          template: { templateVariables },
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    navigate(`/${locale}/properties?search=${keyword}`);
  };

  const { display_search_bar } = display_settings;

  const homeTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-main-title",
    locale,
    defaultLocale
  );
  const homeText = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-main-text",
    locale,
    defaultLocale
  );
  const backgroundImg = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme1Vars[0].slug,
    locale,
    defaultLocale
  );

  return (
    <section
      className={theme1Styles.banner}
      style={{
        backgroundImage: `url(${backgroundImg || theme1Vars[0].image})`,
      }}
      loading="lazy"
    >
      <div className={theme1Styles.wrapper}>
        <div className={`${theme1Styles.bannerTextC}`}>
          <div className={`${theme1Styles.bannerText}`}>
            <h2 className={`${theme1Styles.bannerTl}`}>{homeTitle}</h2>
            <p>{homeText}</p>
            {(() => {
              let placeholder = translateStatic("city", locale, defaultLocale);
              placeholder +=
                ", " + translateStatic("address", locale, defaultLocale);
              placeholder +=
                ", " + translateStatic("zip_code", locale, defaultLocale);

              if (display_search_bar) {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={`${theme1Styles.banSearchBox}`}>
                      <input
                        type=""
                        className={`${theme1Styles.searchField}`}
                        placeholder={placeholder}
                        {...bindKeyword}
                      />
                      <button
                        type="submit"
                        className={`${theme1Styles.searchBtn}`}
                      >
                        {translateStatic("search", locale, defaultLocale)}
                      </button>
                    </div>
                  </form>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
