/* eslint react/prop-types: 0 */
import React from "react";
import { Link } from "gatsby";

import * as theme4Styles from "../scss/theme4.module.scss";
import { getTranslatedValue } from "../../../helpers/translationHelper";
import {
  calculateBedroomsRange,
  calculateBathroomsRange,
} from "../../../helpers/buildingHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";

const BuildingList = ({ buildings, locale, defaultLocale }) => {
  return (
    <div className={`${theme4Styles.productRow}`}>
      {buildings.map((building) => {
        const { pictures, property_type, translations, layouts } = building;

        let picture =
          pictures.length > 0
            ? prepareImageURL(pictures[0], 480, 320, false)
            : property1;

        const bedroomsRange = calculateBedroomsRange(layouts);
        const bathroomsRange = calculateBathroomsRange(layouts);

        return (
          <div className={theme4Styles.colm} key={building.uuid}>
            <div className={`${theme4Styles.productBox}`}>
              <Link
                to={`/${locale}/building/${building.uuid}`}
                className={theme4Styles.image}
              >
                <img src={picture} alt="" />

                {(() => {
                  if (property_type && property_type.translations.length > 0) {
                    return (
                      <span className={theme4Styles.category}>
                        {getTranslatedValue(
                          property_type.translations,
                          "name",
                          locale,
                          defaultLocale
                        )}
                      </span>
                    );
                  }
                })()}

                <div className={`${theme4Styles.infoC}`}>
                  <div className={`${theme4Styles.leftC}`}>
                    <span className={theme4Styles.price}>{building.name}</span>
                    <h4
                      dangerouslySetInnerHTML={{
                        __html:
                          getTranslatedValue(
                            translations,
                            "headline",
                            locale,
                            defaultLocale
                          ) || "",
                      }}
                    ></h4>
                    <h4>{building.location}</h4>
                  </div>
                  <ul className={theme4Styles.features}>
                    {bedroomsRange != 0 && <li>
                      {bedroomsRange}
                      <abbr> Beds</abbr>
                    </li>}
                    {bathroomsRange != 0 && <li>
                      {bathroomsRange}
                      <abbr> Baths</abbr>
                    </li>}
                  </ul>
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BuildingList;
