/* eslint react/prop-types: 0 */
import React from "react";
import { Link } from "gatsby";

import * as theme5Styles from "../scss/theme5.module.scss";
import { getTranslatedValue } from "../../../helpers/translationHelper";
import {
  calculateBedroomsRange,
  calculateBathroomsRange,
} from "../../../helpers/buildingHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";
import bed from "../images/bed.svg";
import bathtub from "../images/bathtub.svg";
import swimming from "../images/swimming-pool.svg";
import buildingtag from "../images/building.png";

const BuildingList = ({ buildings, locale, defaultLocale }) => {
  return (
    <div className={`${theme5Styles.productRow}`}>
      {buildings.map((building) => {
        const { pictures, property_type, layouts, translations } = building;

        const picture =
          pictures.length > 0
            ? prepareImageURL(pictures[0], 480, 320, false)
            : property1;

        const bedroomsRange = calculateBedroomsRange(layouts);
        const bathroomsRange = calculateBathroomsRange(layouts);

        return (
          <div className={theme5Styles.colm} key={building.uuid}>
            <div className={`${theme5Styles.productBox}`}>
              <Link
                to={`/${locale}/building/${building.uuid}`}
                className={theme5Styles.image}
              >
                <img alt="image" src={picture} />

                {(() => {
                  if (property_type && property_type.translations.length > 0) {
                    const propertyType = getTranslatedValue(
                      property_type.translations,
                      "name",
                      locale,
                      defaultLocale
                    );
                    if (propertyType) {
                      return (
                        <span className={`${theme5Styles.buCatg}`}>
                          <span className={`${theme5Styles.tag}`}>
                            <img src={buildingtag} alt="" />
                          </span>
                          {propertyType}
                        </span>
                      );
                    }
                  }
                })()}
              </Link>
              <div className={theme5Styles.details}>
                <h4>{building.name}</h4>
                <div className={`${theme5Styles.category}`}>
                  <span
                    className={`${theme5Styles.catg}`}
                    dangerouslySetInnerHTML={{
                      __html:
                        getTranslatedValue(
                          translations,
                          "headline",
                          locale,
                          defaultLocale
                        ) || "NA",
                    }}
                  ></span>
                </div>
                <p>{building.location}</p>
                <div className={theme5Styles.features}>
                  {bedroomsRange != 0 && <div className={theme5Styles.li}>
                    <img alt="image" src={bed} />
                    <span>{bedroomsRange} Beds</span>
                  </div>}
                  {bathroomsRange != 0 && <div className={theme5Styles.li}>
                    <img alt="image" src={bathtub} />
                    <span>{bathroomsRange} Beds</span>
                  </div>}
                  <div className={theme5Styles.li}>
                    <img alt="image" src={swimming} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BuildingList;
