/* eslint react/prop-types: 0 */
import React from "react";

import Layout from "./components/layout";
import SEO from "./components/seo";
import Banner from "./components/banner";
import Welcome from "./components/welcome";
import PropertyList from "./components/property-list";
import BuildingList from "./components/building-list";
import {
  theme4Vars,
  templateVariableValue,
} from "../../helpers/templateVariables";

import * as theme4Styles from "../theme4/scss/theme4.module.scss";

const Theme4 = ({ result, pageContext }) => {
  const {
    remarso: {
      domainByURL: {
        website: {
          templateConfiguration,
          template: { templateVariables },
          setting: display_settings,
          list,
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = result;

  const { display_projects, display_listings } = display_settings;

  let homeTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-main-title",
    locale,
    defaultLocale
  );
  let homeText = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-main-text",
    locale,
    defaultLocale
  );

  const section1 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-1-text",
    locale,
    defaultLocale
  );
  const section1Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-1-title",
    locale,
    defaultLocale
  );
  const section1Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme4Vars[1].slug,
    locale,
    defaultLocale
  );

  const section2 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-2-text",
    locale,
    defaultLocale
  );
  const section2Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-2-title",
    locale,
    defaultLocale
  );
  const section2Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme4Vars[2].slug,
    locale,
    defaultLocale
  );

  const section3 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-3-text",
    locale,
    defaultLocale
  );
  const section3Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-3-title",
    locale,
    defaultLocale
  );
  const section3Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme4Vars[3].slug,
    locale,
    defaultLocale
  );

  const listingTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-listing-title",
    locale,
    defaultLocale
  );
  const listingCatchline = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-listing-catchline",
    locale,
    defaultLocale
  );

  const buildingTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-building-title",
    locale,
    defaultLocale
  );
  const buildingCatchline = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-building-catchline",
    locale,
    defaultLocale
  );

  const metaTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "meta-title-home",
    locale,
    defaultLocale
  );

  const { properties, buildings } = pageContext;

  return (
    <Layout>
      <SEO metaTitle={metaTitle} />

      <Banner />

      {(() => {
        if (
          display_listings &&
          (list.listing_type == 0 || list.listing_type == 1)
        ) {
          return (
            <section className={theme4Styles.homeContainet}>
              <div className={theme4Styles.wrapper}>
                <h2 className={`${theme4Styles.secTl}`}>
                  {listingTitle}
                  <span>{listingCatchline}</span>
                </h2>
                <PropertyList
                  properties={properties.slice(0, 3)}
                  locale={locale}
                  defaultLocale={defaultLocale}
                />
              </div>
            </section>
          );
        }
      })()}

      {(() => {
        if (
          display_projects &&
          (list.listing_type == 0 || list.listing_type == 2)
        ) {
          return (
            <section className={theme4Styles.homeContainet}>
              <div className={theme4Styles.wrapper}>
                <h2 className={`${theme4Styles.secTl}`}>
                  {buildingTitle}
                  <span>{buildingCatchline}</span>
                </h2>
                <BuildingList
                  buildings={buildings.slice(0, 3)}
                  locale={locale}
                  defaultLocale={defaultLocale}
                />
              </div>
            </section>
          );
        }
      })()}

      {(() => {
        if (
          section1 ||
          section1Title ||
          section1Logo ||
          section2 ||
          section2Title ||
          section2Logo ||
          section3 ||
          section3Title ||
          section3Logo
        ) {
          return (
            <section className={theme4Styles.homeContainet}>
              <div className={theme4Styles.wrapper}>
                {(homeTitle || homeText) && (
                  <h2
                    className={`${theme4Styles.secTl} ${theme4Styles.textCenter} font-black`}
                  >
                    {homeTitle}
                    <span>{homeText}</span>
                  </h2>
                )}
                <div className={theme4Styles.welcomeRow}>
                  {(section1 || section1Title || section1Logo) && (
                    <div className={theme4Styles.colm}>
                      <Welcome
                        title={section1Title}
                        text={section1}
                        logo={section1Logo}
                      />
                    </div>
                  )}

                  {(section2 || section2Title || section2Logo) && (
                    <div className={theme4Styles.colm}>
                      <Welcome
                        title={section2Title}
                        text={section2}
                        logo={section2Logo}
                      />
                    </div>
                  )}

                  {(section3 || section3Title || section3Logo) && (
                    <div className={theme4Styles.colm}>
                      <Welcome
                        title={section3Title}
                        text={section3}
                        logo={section3Logo}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
          );
        }
      })()}
    </Layout>
  );
};

export default Theme4;
