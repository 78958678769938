export const formatPrice = (property, currency) => {
  const { selling_price, rental_price, rental_mode } = property;

  if (!selling_price && !rental_price) {
    return null;
  }

  if (property.currency) {
    if (selling_price) {
      return property.currency.symbol + selling_price;
    } else {
      return (
        property.currency.symbol +
        rental_price +
        (rental_mode ? "/" + rental_mode : "")
      );
    }
  }

  if (currency) {
    if (selling_price) {
      return currency.symbol + selling_price;
    } else {
      return (
        currency.symbol + rental_price + (rental_mode ? "/" + rental_mode : "")
      );
    }
  }
};
