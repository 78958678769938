/* eslint react/prop-types: 0 */
import React from "react";

import Layout from "./components/layout";
import SEO from "./components/seo";
import Banner from "./components/banner";
import Welcome from "./components/welcome";
import PropertyList from "./components/property-list";
import BuildingList from "./components/building-list";
import Contact from "./components/Contact";
import { templateVariableValue } from "../../helpers/templateVariables";
import * as theme5Styles from "../theme5/scss/theme5.module.scss";

const Theme5 = ({ result, pageContext }) => {
  const {
    remarso: {
      domainByURL: {
        website: {
          list,
          templateConfiguration,
          template: { templateVariables },
          setting: display_settings,
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_projects,
    display_listings,
    display_contact_form,
  } = display_settings;

  let mainTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-main-title",
    locale,
    defaultLocale
  );

  const section1 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-1-text",
    locale,
    defaultLocale
  );
  const section1Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-1-title",
    locale,
    defaultLocale
  );

  const section2 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-2-text",
    locale,
    defaultLocale
  );
  const section2Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-2-title",
    locale,
    defaultLocale
  );

  const section3 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-3-text",
    locale,
    defaultLocale
  );
  const section3Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-3-title",
    locale,
    defaultLocale
  );

  const listingTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-listing-title",
    locale,
    defaultLocale
  );
  const listingCatchline = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-listing-catchline",
    locale,
    defaultLocale
  );

  const buildingTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-building-title",
    locale,
    defaultLocale
  );
  const buildingCatchline = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-building-catchline",
    locale,
    defaultLocale
  );

  const metaTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "meta-title-home",
    locale,
    defaultLocale
  );

  const { properties, buildings } = pageContext;

  return (
    <Layout>
      <SEO metaTitle={metaTitle} />

      <Banner />

      <div className={theme5Styles.wrapper}>
        {(() => {
          if (
            display_listings &&
            (list.listing_type == 0 || list.listing_type == 1)
          ) {
            return (
              <section className={`${theme5Styles.homeContainet}`}>
                <h2 className={`${theme5Styles.secTl}`}>
                  {listingTitle}
                  <span>{listingCatchline}</span>
                </h2>
                <PropertyList
                  properties={properties.slice(0, 3)}
                  locale={locale}
                  defaultLocale={defaultLocale}
                />
              </section>
            );
          }
        })()}

        {(() => {
          if (
            display_projects &&
            (list.listing_type == 0 || list.listing_type == 2)
          ) {
            return (
              <section className={`${theme5Styles.homeContainet}`}>
                <h2 className={`${theme5Styles.secTl}`}>
                  {buildingTitle}
                  <span>{buildingCatchline}</span>
                </h2>
                <BuildingList
                  buildings={buildings.slice(0, 3)}
                  locale={locale}
                  defaultLocale={defaultLocale}
                />
              </section>
            );
          }
        })()}

        {(() => {
          if (
            mainTitle ||
            section1 ||
            section1Title ||
            section2 ||
            section2Title ||
            section3 ||
            section3Title
          ) {
            return (
              <section className={`${theme5Styles.homeContainet}`}>
                {mainTitle && (
                  <h2
                    className={`${theme5Styles.secTl} ${theme5Styles.textCenter}`}
                  >
                    {mainTitle}
                  </h2>
                )}
                <div className={theme5Styles.welRow}>
                  {(section1 || section1Title) && (
                    <div className={`${theme5Styles.colm}`}>
                      <Welcome title={section1Title} text={section1} />
                    </div>
                  )}

                  {(section2 || section2Title) && (
                    <div className={`${theme5Styles.colm}`}>
                      <Welcome title={section2Title} text={section2} />
                    </div>
                  )}

                  {(section3 || section3Title) && (
                    <div className={`${theme5Styles.colm}`}>
                      <Welcome title={section3Title} text={section3} />
                    </div>
                  )}
                </div>
              </section>
            );
          }
        })()}

        {(() => {
          if (display_contact_form) {
            return (
              <section className={`${theme5Styles.homeContainet}`}>
                <h2 className={`${theme5Styles.secTl}`}>Contact</h2>
                <Contact />
              </section>
            );
          }
        })()}
      </div>
    </Layout>
  );
};

export default Theme5;
